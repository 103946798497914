"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateInvoiceTemplate = exports.deleteInvoiceFile = exports.deleteInvoiceItem = exports.changeSubStatus = exports.suspendInvoice = exports.declineInvoice = exports.changeStatus = exports.getUserSignatories = exports.getPurchasedItems = exports.notifyDepartments = exports.updateInvoice = exports.createInvoice = exports.getInvoice = exports.updateInvoiceItem = exports.getInvoiceItemsForm = exports.getInvoiceItems = exports.deleteInvoice = exports.getInvoices = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/documents/purchases/invoices/parsers");
const react_toastify_1 = require("react-toastify");
const getInvoices = async (params) => {
    const response = await Api.get('documents/purchases/invoices', params);
    return (0, parsers_1.parseInvoices)(response);
};
exports.getInvoices = getInvoices;
const deleteInvoice = async (id) => {
    const response = await Api.deleteItem('documents/purchases/invoices/' + id);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return response;
};
exports.deleteInvoice = deleteInvoice;
const getInvoiceItems = async (id, page) => {
    const response = await Api.get(`documents/purchases/invoices/${id}/items`, { page });
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseInvoiceItems)(response);
};
exports.getInvoiceItems = getInvoiceItems;
const getInvoiceItemsForm = async (id, params) => {
    const response = await Api.get(`documents/purchases/invoices/${id}/items`, params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseInvoiceItemsForm)(response);
};
exports.getInvoiceItemsForm = getInvoiceItemsForm;
const updateInvoiceItem = async (id, params) => {
    const response = await Api.patch(`documents/purchases/invoices/${id}/items`, params);
    return (0, parsers_1.parseInvoiceItemsForm)(response);
};
exports.updateInvoiceItem = updateInvoiceItem;
const getInvoice = async (id) => {
    const response = await Api.get('documents/purchases/invoices/' + id);
    return (0, parsers_1.parseInvoice)(response);
};
exports.getInvoice = getInvoice;
const createInvoice = async (params) => {
    const response = await Api.post('documents/purchases/invoices', params);
    return (0, parsers_1.parseInvoice)(response);
};
exports.createInvoice = createInvoice;
const updateInvoice = async (id, params) => {
    const response = await Api.patch('documents/purchases/invoices/' + id, params);
    return (0, parsers_1.parseInvoice)(response);
};
exports.updateInvoice = updateInvoice;
const notifyDepartments = async (id, params) => {
    const response = await Api.patch('documents/purchases/invoices/notify-deps/' + id, params);
    return (0, parsers_1.parseInvoice)(response);
};
exports.notifyDepartments = notifyDepartments;
const getPurchasedItems = async (params) => {
    const response = await Api.get('documents/purchases/invoices/purchased-items', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parsePurchasedItems)(response);
};
exports.getPurchasedItems = getPurchasedItems;
const getUserSignatories = async (params) => {
    const response = await Api.get('documents/purchases/invoices/user-signatories', params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return (0, parsers_1.parseUserSignatories)(response);
};
exports.getUserSignatories = getUserSignatories;
const changeStatus = async (id) => {
    const response = await Api.patch('documents/purchases/invoices/change-status/' + id, {});
    return (0, parsers_1.parseInvoice)(response);
};
exports.changeStatus = changeStatus;
const declineInvoice = async (declineReason, invoiceId) => {
    const response = await Api.patch('documents/purchases/invoices/decline/' + invoiceId, { declineReason });
    return (0, parsers_1.parseInvoice)(response);
};
exports.declineInvoice = declineInvoice;
const suspendInvoice = async (suspendReason, invoiceId) => {
    const response = await Api.patch('documents/purchases/invoices/suspend/' + invoiceId, { suspendReason });
    return (0, parsers_1.parseInvoice)(response);
};
exports.suspendInvoice = suspendInvoice;
const changeSubStatus = async (id) => {
    const response = await Api.patch('documents/purchases/invoices/change-sub-status/' + id, {});
    return (0, parsers_1.parseInvoice)(response);
};
exports.changeSubStatus = changeSubStatus;
const deleteInvoiceItem = async (id, params) => {
    const response = await Api.deleteItem(`documents/purchases/invoices/${id}/remove-items`, params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return { status: response.status, errors: response.errors };
};
exports.deleteInvoiceItem = deleteInvoiceItem;
const deleteInvoiceFile = async (id, params) => {
    const response = await Api.patch('documents/purchases/invoices/remove-files/' + id, params);
    if (response.errors !== null)
        response.errors.map(el => react_toastify_1.toast.error(el.message));
    return response;
};
exports.deleteInvoiceFile = deleteInvoiceFile;
const generateInvoiceTemplate = async (id) => {
    const response = await Api.get(`documents/purchases/invoices/update-template/${id}`);
    return (0, parsers_1.parseGenerateInvoiceTemplate)(response);
};
exports.generateInvoiceTemplate = generateInvoiceTemplate;
